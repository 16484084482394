import { send } from 'emailjs-com';
import React, { useState } from 'react';
import Button from './Button';

const ContactForm = () => {
  const [toSend, setToSend] = useState({
    from_name: '',
    to_name: '',
    message: '',
    reply_to: '',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send('service_gii19qx', 'template_h8zfkai', toSend, 'ChpK43eab-hvTNEid')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className="text-left m-auto lg:m-0" style={{ width: '340px' }}>
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 lg:mb-0" onSubmit={onSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="formname">
            Тема
          </label>
          <input
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="from_name"
            id="formname"
            placeholder="Тема сообщения"
            value={toSend.from_name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
            Сообщение
          </label>
          <textarea
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="message"
            placeholder="Ваше сообщение"
            value={toSend.message}
            onChange={handleChange}
            rows={10}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          {/* <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Отправить
          </button> */}
          <Button type="submit">Отправить</Button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
