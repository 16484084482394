import React from 'react';
import qrInsta from './images/amaranta_instagram_qr.jpg';
import qrFb from './images/amaranta_fb_qr.jpg';

const SocialsQRs = () => (
  <div className="flex flex-col gap-6">
    <img
      className="w-100 m-auto shadow-md"
      style={{ maxWidth: '220px' }}
      src={qrInsta}
      alt="qr-insta"
    />
    <img className="w-100 m-auto shadow-md" style={{ maxWidth: '220px' }} src={qrFb} alt="qr-fb" />
  </div>
);

export default SocialsQRs;
