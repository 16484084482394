import React from 'react';
import hero from './images/hero-background.jpg';

const HeroImg = () => (
  <div>
    <img src={hero} alt="hero" />
  </div>
);

export default HeroImg;
