import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImg from '../components/HeroImg';
import { CollectionImg, CollectionImgTypes } from '../components/CollectionImg';
import ContactForm from '../components/ContactForm';
import SocialsQRs from '../components/SocialsQRs';
import collectionImg from '../components/images/collection/flame.jpg';
import masterClassImg from '../components/images/master-class.jpg';
import personalPerfumeImg from '../components/images/personal-perfume.jpg';

const Index = () => (
  <Layout>
    <section className="py-10 md:py-20">
      <div className="container mx-auto px-8 lg:flex lg:justify-center">
        <div className="text-center lg:text-left lg:flex lg:flex-col lg:justify-items-center lg:items-center">
          <h1
            className="text-4xl lg:text-5xl xl:text-6xl leading-none"
            style={{ color: '#8224e3' }}
          >
            <strong className="font-bold">Amaranta Perfumes</strong>
          </h1>
          <p className="text-xl lg:text-2xl my-6 font-light">
            My creations are the best compliment to your beauty
          </p>
          <HeroImg />
        </div>
      </div>
    </section>
    <section id="features" className="lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Лучшее для лучших</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8 h-full">
              <img src={collectionImg} alt="" />
              <p className="font-semibold text-xl mt-4">Ароматы коллекции</p>
              <p className="mt-4">
                Каждый аромат коллекции создается по авторской формуле вручную в лаборатории
                <strong>«Amaranta Perfumes»</strong> ограниченным тиражом. Аналогов нет.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8 h-full">
              <img src={personalPerfumeImg} alt="" />
              <p className="font-semibold text-xl mt-4">Персональный аромат</p>
              <p className="mt-4">
                <b>Мы разработаем Ваш личный код</b> – аромат, созданный только для Вас!
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8 h-full">
              <img src={masterClassImg} alt="" />
              <p className="font-semibold text-xl mt-4">Мастер-класс</p>
              <p className="mt-4">
                Мы проводим мастер-классы, на которых Вы научитесь создавать авторский парфюм
                собственными руками, а также углубитесь в историю и теорию парфюмерного дела.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <article id="perfumes" className="pt-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Коллекция</h2>
      </div>
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Moon Angel</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: вербена, травы, элеми
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: лилия, жасмин, ландыш, индийская
                роза, роза
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: древесные ноты, смолы
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.moon_angel} />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">Mysterious</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: кориандр, кардамон, бергамот, лимон,
                мандарин
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: герань, жасмин, лавр, кипарис
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: дубовый мох, сандал, эбеновое дерево
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.misterious} />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">The Shining</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: ананас, апельсин, роза, чай с
                ментолом, анис, элеми
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: сосна, можжевельник, шалфей
                мускатный, розовое дерево, Франжипани
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: древесные ноты, дягиль, бобы тонка,
                серая амбра, мускус
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.shining} />}
      />

      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">The Flame</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: клубника, инжир, малина, виноград
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: роза марокко, лабданум, слива,
                цикламен, индийская роза, кожа, какао
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: мадагаскарская ваниль, нероли яра-яра,
                семена амбретты, сантал, желтый мускус, амбра, замша
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.flame} />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Lady Twilight</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: кардамон, красный мандарин,
                сычуаньский перец, полынь
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: жасмин, древесные ноты, черная роза
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: мускус, дубовый мох, семена амбретты,
                кастореум, стиракс
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.lady_twilight} />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">Noble Estate</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: лайм, вишня, древесный аккорд
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: мирт, коньяк, авторский
                древесно-дымный аккорд, кожа, розовое дерево, бальзамы, иланг-иланг
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: мирра критская, сандаловое дерево, чоя
                раль, дубовый мох, кедр, ваниль
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.noble_estate} />}
      />

      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Nordic Flowers</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: смородина, франжипани, лилия
                имбирная, сирень, мимоза
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: ирис, фиалка, роза, липа,
                иланг-иланг, гелиотроп, жасмин, нероли
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: сандал, кедр, дубовый мох, белое дерево
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.nordic_flowers} />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">First Love</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: роза, лотос, цикламен, мед, липа,
                сирень, бигарди, грейпфрут, клубника
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: нероли, ладанник, белые цветы,
                иланг-иланг, лист смородины, инжир, тубероза, франжипани
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: древесные ноты, сантал, ваниль, пачули,
                амбра, мускус, кедр, гвоздика, специи
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.first_love} />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Mara</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: роза, герань, лимон, мед,
                сицилийский(кровавый) апельсин
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: нероли, жасмин, мускатный орех,
                специи
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: ладан, ветивер, мореное дерево, дубовый
                мох, ваниль, красная амбра, желтый мускус
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.mara} />}
      />

      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">Mad Rose</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: цветок кактуса «королева ночи»,
                мандарин, полынь, кардамон, перец
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: ладан, гвоздика, роза, специи, лилия
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: кастореум, деготь, кожа, стиракс, белый
                мускус, черная амбра, дубовый мох
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.mad_rose} />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Starfall</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: жасмин ночной, шафран, клементин,
                белый перец
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: клубника, серая амбра, эбеновое
                дерево, ладанник, хвойные ноты
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: бобы тонка, амбра, кедр, мирра, мускус,
                смолы, древесные ноты
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.starfall} />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">Asgard</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: альдегиды, травы, лаванда, цитрусы,
                фиалка
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: корица, специи, цветочные ноты,
                лаванда, дамасская роза
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: древесные ноты, кастореум, кожа, деготь,
                бобы тонка
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.asgard} />}
      />

      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Talisman</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: ананас, кокос, апельсин, мандарин
                сицилийский, грейпфрут, бергамот
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: жасмин, белые цветы, тубероза,
                нероли, иланг-иланг, пион, мангнолия, роза, цикламен, ландыш
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: древесные ноты, циветта мускус, амбра,
                амброксан, кедр, мускус
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.talisman} />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">The Sisters</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: апельсин сладкий, слива, груша, инжир
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: цветы апельсинового дерева(абсолю),
                фиалка, жасмин madurai, тиаре, франжипани
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: древесные ноты, ваниль, бобы тонка,
                сантал, амбра, шоколад, амброксан
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.sisters} />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Running on the Waves</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: апельсин, беграмот, лимон, морские
                ноты
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: ром, роза, ромашка, мореное дерево,
                жасмин, лаванда
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: древесные ноты, серая амбра
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.running_waves} />}
      />

      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">Amber Wings</h3>
            <ul className="mt-6 text-xl leading-relaxed">
              <li className="text-sm">
                <b className="font-semibold">Верхние ноты</b>: анис, смородина, вишня, клубника,
                кориандр, розовое дерево, тубероза, цитрусы
              </li>
              <li className="text-sm">
                <b className="font-semibold">Средние ноты</b>: ладан, кориандр, смолы, жимолость,
                роза, лист смородины, франжипани, ветивер
              </li>
              <li className="text-sm">
                <b className="font-semibold">Ноты базы</b>: корица, янтарь, пачули, смолы, ваниль,
                амбра, циветта мускус, кедр вирджинский, ладан, сантал, древесные ноты
              </li>
            </ul>
          </div>
        }
        secondarySlot={<CollectionImg type={CollectionImgTypes.amber_wings} />}
      />
    </article>
    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map((customer) => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    <section
      id="contacts"
      className="container mx-auto mb-0 mt-20 lg:mb-20 py-24 bg-gray-200 rounded-lg text-center"
    >
      <h3 className="text-5xl font-semibold">Контакты</h3>
      <p className="px-8">
        Присоединяйтесь к нам в социальных сетях или <br /> оставляйте запрос через форме - будем
        рады знакомству!
      </p>
      <div className="flex flex-col lg:flex-row lg:justify-center gap-10 mt-10">
        <SocialsQRs />
        <ContactForm />
      </div>
    </section>
  </Layout>
);

export default Index;
