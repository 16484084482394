import React from 'react';

export const CollectionImgTypes = {
  moon_angel: `moon_angel`,
  misterious: 'misterious',
  shining: 'shining',
  flame: 'flame',
  lady_twilight: 'lady_twilight',
  noble_estate: 'noble_estate',
  nordic_flowers: 'nordic_flowers',
  first_love: 'first_love',
  mara: 'mara',
  mad_rose: 'mad_rose',
  starfall: 'starfall',
  asgard: 'asgard',
  talisman: 'talisman',
  sisters: 'sisters',
  running_waves: 'running_waves',
  amber_wings: 'amber_wings',
};

export const CollectionImg = ({ className, type }) => {
  return (
    <img
      src={`/images/collection/${CollectionImgTypes[type]}.jpg`}
      className={className}
      alt="perfume-img"
    />
  );
};
